<template>
  <div>
    <shipMsg :header='header' pageType='shareDetail'  v-loading="loading"></shipMsg>
    <div class="searchBox">
      <div>
          <el-date-picker
            :clearable='true'
            v-model="searchQuery.time"
            type="daterange"
            size="small"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
          <el-input style="width:230px;margin-left:20px" clearable v-model="searchQuery.name" size="small" placeholder="请输入客户名称"></el-input>
      </div>
      <div>
        <el-button size="small" type="primary" @click="searchChange">查询</el-button>
      </div>
    </div>
    <div style="margin-top:10px">
      <el-table
        :data="tableData"
        style="width: 100%"
        :height="tableHeight"
        v-tableHeight="{ bottomOffset: 74 }"
      >
        <el-table-column prop="date" label="客户" width="230" :show-overflow-tooltip="true">
          <span slot-scope="scope">{{scope.row.client | textFormat}}</span>
        </el-table-column>
        <el-table-column prop="date" label="分享备注" :show-overflow-tooltip="true">
          <span slot-scope="scope">{{scope.row.remarks | textFormat}}</span>
        </el-table-column>
        <el-table-column prop="date" label="船公司" width="180" :show-overflow-tooltip="true">
          <span slot-scope="scope">{{scope.row.shipping_company | textFormat}}</span>
        </el-table-column>
        <el-table-column prop="date" label="分享运价" width="180" :show-overflow-tooltip="true">
          <div slot-scope="scope">
            <div v-for="(item,i) in scope.row.historical_price" :key="i">{{item.price}}  {{item.code}}/{{item.unit}}</div>
          </div>
        </el-table-column>
        <el-table-column prop="date" label="分享时间" width="300" :show-overflow-tooltip="true">
          <span slot-scope="scope">{{scope.row.created_time | secondFormat("LLL")}}</span>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBlock">
      <el-pagination
        @current-change="handleChange"
        :page-size="10"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import shipMsg from "~bac/components/ymp/shipMsg";
export default {
  name: "shareRecords_details",
  components:{
    shipMsg
  },
  data() {
    return {
        tableHeight:100,
        loading:false,
        start:1,
        header:{},
        total:0,
        searchQuery:{
          time:"",
          name:"",
        },
        tableData: []
    };
  },
  mounted() {
    this.searchQuery.name=this.$route.query.name
    if(this.$route.query.start_time && this.$route.query.end_time){
      this.searchQuery.time=[this.$route.query.start_time*1000,this.$route.query.end_time*1000]
    }
    this.getDetail();
  },
  methods: {
    handleChange(e){
      this.start=e
      this.getDetail()
    },
    searchChange(){
      this.start=1
      this.getDetail()
    },
    async getDetail(){
      console.log('this.$route.query',this.$route.query);
      let params={
        user_id: this.USER_INFO.id,
        freight_id:this.$route.query.freight_id,
        start:this.start-1,
        limit:10,
        nopage:0,
        client:this.searchQuery.name,
        freight_type:this.$route.query.freight_type,
        from_port:this.$route.query.from_port,
        trans_port:this.$route.query.trans_port,
        dest_port:this.$route.query.dest_port,
      }
      if(this.searchQuery.time){
         params.start_time=this.$moment(this.searchQuery.time[0]).unix()
         params.end_time=this.$moment(this.searchQuery.time[1]).unix()+86399
      }
      console.log('params',params);
      let res=await this.$store.dispatch(
        "baseConsole/freight_share_detail",
        params
      )

      res.data.info_list.forEach(item => {
        item.historical_price=JSON.parse(item.historical_price)
      });
      console.log(res.data.info_list);
      this.tableData=res.data.info_list
      this.total=res.count

      this.header=Object.assign({},res.data)
      delete this.header.info_list
    },
  },
};
</script>

<style scoped lang="less">
  .searchBox{
    display: flex;
    justify-content: space-between;
    background: #fff;
    padding: 15px;
    margin-top: 10px;
  }
</style>
