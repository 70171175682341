<template>
    <div class="header" style="height:auto">
      <div class="heraderTop" v-if="pageType=='common'">
          <div class="div1">
            <div class="portEn" :title="header.from_port | textFormat">
              {{ header.from_port | textFormat }}
            </div>
            <div class="portZh" :title="header.from_port_name_zh | textFormat">
              {{ header.from_port_name_zh | textFormat }}
            </div>
          </div>
          <div class="div2">
            <div class="top">
              <span
                class="trans"
                v-if="header.trans_port"
                :title="header.trans_port | textFormat"
                >{{ header.trans_port | textFormat }}</span
              >
              <span v-if="header.trans_port" class="trans" style="width:auto;font-size: 16px">转</span>
              <span v-else>直达</span>
            </div>
            <img   src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/ymp/rightarrow.png" alt="">
            <!--            隐藏航线-->
            <div :title="header.shipping_line_name_zh" class="trans_port">
              {{ header.shipping_line_name_zh | textFormat }}
            </div>
          </div>
          <div class="div3">
            <div class="portEn" :title="header.dest_port | textFormat">
              {{ header.dest_port | textFormat }}
            </div>
            <div class="portZh" :title="header.dest_port_name_zh | textFormat">
              {{ header.dest_port_name_zh | textFormat }}
            </div>
          </div>
        </div>

      <div class="heraderTop" v-if="pageType=='share'">
          <div class="div1">
              <div class="portEn" :title="header.from_port_en | textFormat">
              {{ header.from_port_en | textFormat }}
              </div>
              <div class="portZh" :title="header.from_port_zh | textFormat">
              {{ header.from_port_zh | textFormat }}
              </div>
          </div>
          <div class="div2">
            <div class="top">
              <span
                class="trans"
                v-if="header.trans_port_en"
                :title="header.trans_port_en | textFormat"
                >{{ header.trans_port_en | textFormat }}</span
              >
              <span v-if="header.trans_port_en" class="trans" style="width:auto;font-size: 16px">转</span>
              <span v-else>直达</span>
            </div>
            <img   src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/ymp/rightarrow.png" alt="">
            <!--            隐藏航线-->
            <div :title="header.shipping_line_name_zh" class="trans_port">
              {{ header.shipping_line_name_zh | textFormat }}
            </div>
          </div>
          <div class="div3">
              <div class="portEn" :title="header.dest_port_zh | textFormat">
              {{ header.dest_port_en | textFormat }}
              </div>
              <div class="portZh" :title="header.dest_port_zh | textFormat">
              {{ header.dest_port_zh | textFormat }}
              </div>
          </div>
        </div>

      <div class="heraderTop" v-if="pageType=='inquery'">
          <div class="div1" >
            <div class="portEn" :title="header.from_port_name_en | textFormat">
              {{ header.from_port_name_en | textFormat }}
            </div>
            <div class="portZh" :title="header.from_port_name_zh | textFormat">
              {{ header.from_port_name_zh | textFormat }}
            </div>
          </div>
          <div class="div2">
            <div class="top">
              <span
                class="trans"
                v-if="header.trans_port_name_en"
                :title="header.trans_port_name_en | textFormat"
                >{{ header.trans_port_name_en | textFormat }}</span
              >
              <span v-if="header.trans_port_name_en" class="trans" style="width:auto;font-size: 16px">转</span>
              <span v-else>直达</span>
            </div>
            <img   src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/ymp/rightarrow.png" alt="">
            <!--            隐藏航线-->
            <div :title="header.shipping_line_name_zh" class="trans_port">
              {{ header.shipping_line_name_zh | textFormat }}
            </div>
          </div>
          <div class="div3">
              <div class="portEn" :title="header.dest_port_name_en | textFormat">
              {{ header.dest_port_name_en | textFormat }}
            </div>
            <div class="portZh" :title="header.dest_port_name_zh | textFormat">
              {{ header.dest_port_name_zh | textFormat }}
            </div>
          </div>
        </div>

      <div class="heraderTop" v-if="pageType=='shareDetail'">
          <div class="div1" >
            <div class="portEn" :title="header.from_port | textFormat">
              {{ header.from_port | textFormat }}
            </div>
            <div class="portZh" :title="header.from_port_zh | textFormat">
              {{ header.from_port_zh | textFormat }}
            </div>
          </div>
          <div class="div2">
            <div class="top">
              <span
                class="trans"
                v-if="header.trans_port"
                :title="header.trans_port | textFormat"
                >{{ header.trans_port | textFormat }}</span
              >
              <span v-if="header.trans_port" class="trans" style="width:auto;font-size: 16px">转</span>
              <span v-else>直达</span>
            </div>
            <img   src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/ymp/rightarrow.png" alt="">
            <!--            隐藏航线-->
            <div  class="trans_port">
              {{ header.freight_type==1?'整箱':'拼箱' }}
            </div>
          </div>
          <div class="div3">
              <div class="portEn" :title="header.dest_port | textFormat">
              {{ header.dest_port | textFormat }}
            </div>
            <div class="portZh" :title="header.dest_port_zh | textFormat">
              {{ header.dest_port_zh | textFormat }}
            </div>
          </div>
        </div>

      <div class="heraderButtom" v-if="pageType!='shareDetail'">
        <div class="heraderButtom_line">
            <div class="ship_item_msg"  :title="header.shipping_company_code | textFormat">
              船公司: <span>{{  header.shipping_company_code | textFormat }}</span> 
            </div>
            <div class="ship_item_msg" v-if="USER_INFO.user_type == 2" :title="header.booking_agent_code | textFormat">
              订舱来源: <span>{{  header.booking_agent_code | textFormat }}</span> 
            </div>
            <div class="ship_item_msg"   :title="header.shipping_date | weekDaysFormat" >
              船期: <span> {{ header.shipping_date | weekDaysFormat }}</span>
            </div>
            <div class="ship_item_msg"  :title="header.days | daysFormat">
              航程:<span>{{ header.days | daysFormat }}</span>  
            </div>
            <div class="ship_item_msg" :title="header.affiliated_terminal | textFormat">
              挂靠码头:<span> {{ header.affiliated_terminal | textFormat }}</span> 
            </div>
            <!-- <div class="ship_item_msg" style="width:280px">
              有效期: <span>{{ header.validity_start | secondFormat("LL") }} 至 {{ header.validity_end | secondFormat("LL") }}</span> 
            </div> -->
            <!-- 锁价方式：1-开航日期，2-开港日期，3-船班 -->
            <div class="ship_item_msg" style="width:280px">
              <span v-if="header.lock_price_way==1 || header.lock_price_way==2">有效期：{{ header.validity_start | secondFormat("LL") }} 至 {{ header.validity_end | secondFormat("LL") }}</span> 
              <span v-else-if="header.lock_price_way==3">锁价:  {{  header.vessel | textFormat }} / {{  header.voyage | textFormat }}</span> 
              <span v-else>有效期：-</span>
            </div>
        </div>
        <div class="heraderButtom_line_2" :style="{width: USER_INFO.user_type == 2 ? '1230px':'1050px'}">
            <span class="r_lab">备注：</span>
            <span class="r_text">{{  header.remarks | textFormat }}</span>
            <!-- <div class="ship_item_msg" :title="header.remarks">
               <span class="text_wrap"> </span>
            </div> -->
        </div>
      </div>
    </div>
</template>
<script>
export default {
    props:{
        pageType:{
          type:String,
          default:'common'
        },
        header:{
            type:Object,
            default:function () {
                return {}
            }
        }
    }
}
</script>
<style scoped  lang="less">
  .header {
  background: #fff;
  padding: 24px 0;
  .heraderTop {
    display: flex;
    justify-content: center;
    text-align: center;
    .div1 {
      // max-width: 250px;
    }
    .div2 {
      position: relative;
      padding-top: 18px;
      max-width: 250px;
      margin: 0 20px;
      >div{
        width: 100%;
      }
      .top{
        position: absolute;
        top: 9px;
        padding: 0px 15px;
        .trans{
          width: 150px;
          display: inline-block;
          overflow     : hidden;
          white-space  : nowrap !important;
          text-overflow: ellipsis;
        }
      }


    }
    .div3 {
      // max-width: 250px;
    }
    .portEn {
      color: #91be42;
      font-size: 24px;
      font-weight: 800;
    }
    .portZh {
      margin-top: 6px;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.65);
    }
    .line {
      width: 150px;
      height: 1px;
      background: #cdcdcd;
      margin: 5px auto;
      position: relative;
      overflow: visible;
      .jiantou {
        position: absolute;
        color: #cdcdcd;
        right: -1px;
        top: -10px;
      }
    }
    .trans_port {
      color: #333333;
      font-size: 16px;
    }
    div {
      overflow: hidden;
      white-space: nowrap !important;
      text-overflow: ellipsis;
    }
  }
}


  .heraderButtom {
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 50px;
    
    .heraderButtom_line{
        display: flex;
        justify-content: center;
        .ship_item_msg{
          width: 140px;
          height: 22px;
          font-size: 14px;
          font-weight: 400;
          color: #3C5058;
          line-height: 22px;
          margin-right:40px;
          span{
            color:#298df8;
          }
          .text_wrap{
            // display: inline-block;
            // width: 730px;
            word-break:break-all;
            overflow: visible;
            white-space: normal !important;
            text-overflow: ellipsis;
            height: auto;
          }
        }

    }
    .heraderButtom_line_2{
        padding-top:10px;
        width:1050px;
        margin: auto;
        .r_lab{
          font-size: 14px;
          font-weight: 400;
          color: #3C5058;
          line-height: 22px;
        }
        .r_text{
          color:#298df8;
          word-break:break-all;
            overflow: visible;
            white-space: normal !important;
            text-overflow: ellipsis;
            height: auto;
        }
        
    }
    div {
      overflow: hidden;
      white-space: nowrap !important;
      text-overflow: ellipsis;
    }
    span {
      color: #298df8;
      margin-left: 4px;
    }
  }
</style>

